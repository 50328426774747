import React, { Component } from 'react'
import Portfolio from './Portfolio'

class Content extends Component {
    render() { 
        if(typeof this.props.pageContent !== 'undefined'){
            if(this.props.pageContent.Title==='Portfolio'){
                //special portolio page
                document.body.classList.add('portfolio_page');
                return ( <div className="content"><div className="innercontent" dangerouslySetInnerHTML={{ __html: '<h1>' + this.props.pageContent.Title + '</h1>' + this.props.pageContent.Desc }} ></div><Portfolio websites={this.props.websites} /></div> );
            }else{
                document.body.classList.remove('portfolio_page');
                return ( <div className="content"><div className="innercontent" dangerouslySetInnerHTML={{ __html: '<h1>' + this.props.pageContent.Title + '</h1>' + this.props.pageContent.Desc }} ></div></div> );
            }
        }else{
            //404 page
            return <div className="content">
                    <div className="innercontent">
                        <h1>Page not found</h1>
                        <p>Oops, it seems you have reached a dead end here; this is a 404 page - which means the page you were looking for does not exist, anymore...</p>
                        <p>Hey, why not <a href="mailto:dagan@solid-code.co.uk" rel="noopener noreferrer" target="_blank">Email me</a> and I will do my best to get it sorted ASAP</p>
                        <p>Thanks, and sorry again..</p>
                    </div>
                </div>
        }
    }
}
 
export default Content;