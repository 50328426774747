import React, { Component } from 'react'
import { Route, NavLink, Link } from "react-router-dom"

class Bar extends Component {
    listpages = (items) => {
        let nav = [];
        for(var item in items){
            nav.push(<li key={item}><NavLink to={items[item].URL} exact activeClassName="active">{items[item].MetaTitle}</NavLink></li>);
        }
        return nav; 

    };

    render() { 
        return ( <div className="bar">
            <Link className="porfoliobtn" to="/">X</Link>
            <ul>
                {this.listpages(this.props.navItems)}
            </ul>
            <Route render={routeProps => {
                if(this.props.currentPath!==routeProps.location.pathname){
                    var self = this;
                    if(this.props.currentPath!=='')
                        document.body.classList.add('slide_loading');
                    window.setTimeout(function(){
                        document.body.classList.remove('slide_loading');
                        self.props.onPathChanged(routeProps.location.pathname);
                    }, 600);
                }
            }} />
            <p className="copyright">&copy; 2019 Solid Code<br /><small>Version: 1.0.0</small></p>
          </div> );
    }
}
 
export default Bar;