import React, { Component } from 'react'
import { BrowserRouter as Router } from "react-router-dom"
import { Helmet } from 'react-helmet'
import './App.min.css'
import Bar from './Bar'
import Content from './Content'
import ReactGA from 'react-ga'

class App extends Component {
  state = { 
      websiteData: [],
      Pages: [],
      currentPath: '',
      currentPage: '',
      analyticsPage: ''
  }

  componentDidMount(){
    //initialize website data
    fetch(this.props.siteURL + '?sc_action=getPages').then(response => {
      response.json().then(Pages => {
          this.setState({Pages});
          // console.log(Pages);
        }
      );
    });
    fetch(this.props.siteURL + '?sc_action=getWebsites').then(response => {
        response.json().then(websiteData => {
            this.setState({websiteData});
            // console.log(websiteData);
          }
        );
    });

    //initialize Google Analytics tracker
    if(navigator.onLine)
      ReactGA.initialize('UA-23112641-1');
  }
  
  pathChanged = (currentPath) => {
    const tmpURL = this.props.siteURL + currentPath.replace("/","");
    
    //log analytics
    let tmpAnalytics = currentPath;
    if(this.state.analyticsPage!==tmpAnalytics){
      //record page view
      if(navigator.onLine)
        ReactGA.pageview(tmpAnalytics);
    }

    this.setState({
      currentPath: currentPath,
      currentPage: tmpURL,
      analyticsPage: tmpAnalytics
    });
  };
  
  render() { 
    return ( <React.Fragment>
      <Helmet>
        <title>Solid Code - {this.state.currentPage!=='' ? (typeof this.state.Pages[this.state.currentPage] !== 'undefined') ? this.state.Pages[this.state.currentPage].MetaTitle : 'Page not found' : ''}</title>
        <meta name="description" content={this.state.currentPage!=='' ? (typeof this.state.Pages[this.state.currentPage] !== 'undefined') ? this.state.Pages[this.state.currentPage].Summary : '' : ''} />
      </Helmet>
      <Router>
        <Bar onPathChanged={this.pathChanged} navItems={this.state.Pages} currentPath={this.state.currentPath} />
        {this.state.currentPage!=='' ? <Content websites={this.state.websiteData} pageContent={this.state.Pages[this.state.currentPage]} /> : ''}
      </Router>
    </React.Fragment> );
  }
}
 
export default App;