import React, { Component } from 'react'
class Porfolio extends Component {
    updateWidths(){
        const websiteWidth = document.querySelector('.content').clientWidth - 80;
        var websites = document.querySelectorAll('.websiteinner');
        websites.forEach(function(elm){
            elm.style.width = websiteWidth + 'px';
        });
    }
    componentDidMount(){
        this.updateWidths();
        var self = this;
        window.addEventListener('resize', function(){
            self.updateWidths();
        });
        //set last check 300ms after
        window.setTimeout(function(){
            self.updateWidths();
        }, 300);
    }
    render() { 
        return ( <div className="portfolio">
            <div className="portfolio_gallery">
                {this.props.websites.map((elm, indx) => {
                    return <a href={elm.Link} target="_blank" rel="noopener noreferrer" key={indx} className="website">
                            <div className="websiteinner">
                                <h3>{elm.Title}</h3>
                                <img title={elm.Title} alt={elm.Title} src={elm.Large} />
                                <p>{elm.Desc}</p>
                            </div>
                        </a>
                })}
            </div>
        </div> );
    }
}
 
export default Porfolio;