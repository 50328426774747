import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
// import * as serviceWorker from './serviceWorker';

const root = document.getElementById('root');
document.body.classList.add('loading');

// const siteURL = "https://www.daganwordpress.co.uk/";
const siteURL = "https://www.solid-code.co.uk/";

window.setTimeout(function(){
    ReactDOM.render(<App siteURL={siteURL} />, root);
    document.body.classList.remove('loading');
}, 300);

// serviceWorker.unregister();

// Check that service workers are supported
if (window.location.hostname !== 'localhost' && 'serviceWorker' in navigator) {
    // Use the window load event to keep the page load performant
    window.addEventListener('load', () => {
        navigator.serviceWorker.register(siteURL + 'service-worker.min.js')
        .then(function(registration){
            // if(!registration.active){
            //     //new sw
            //     console.log('new sw - get cache');
            // }

            // //check for updates
            // navigator.serviceWorker.addEventListener('message', function(event){
            //     if(event.data==='Updated'){
            //         console.log('updated sw - get cache');
            //     }
            // });
        });

    });
}